<template>
    <div class="custRepeat">
        <div class="content">
            <p class="title">
                <span style="margin-right: 16px">查询</span>
                <el-input
                    size="small"
                    style="width: 180px"
                    placeholder="付款方"
                    v-model="tradeNoVal"
                    clearable
                >
                </el-input>
                <el-button
                    type="primary"
                    style="
                        margin-right: 16px;
                        border-color: #2370eb;
                        background: #2370eb;
                    "
                    size="small"
                    @click="getData(obj)"
                    icon="el-icon-search"
                ></el-button>
            </p>
            <el-table
                class="table-box"
                ref="multipleTable"
                :data="tableData"
                height="400px"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '500',
                }"
            >
                <el-table-column
                    label="操作时间"
                    show-overflow-tooltip
                    width="100"
                >
                    <template slot-scope="scope">
                        <span>{{
                            $tableDataHandle.createTime({
                                createTime: scope.row.createTime,
                            })
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="操作人员"
                    prop="adminName"
                    width="80"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    label="操作日志"
                    prop="remark"
                    show-overflow-tooltip
                >
                </el-table-column>
                <div slot="empty" class="empty">
                    <img src="@/assets/zanwushuju.png" />
                    <span style="display: block">暂无数据～</span>
                </div>
            </el-table>
        </div>
    </div>
</template>
<script>
import { arriveHistory } from '@/api/user/finance/write';
export default {
    data() {
        return {
            tableData: [],
            tableRadio: '',
            money: '',
            tradeNoVal: '',
            disabled: true,
        };
    },
    methods: {
        getData() {
            this.disabled = true;
            let data = {
                param: {},
                pageNum: 0,
                pageSize: 0,
            };
            if (this.tradeNoVal) {
                data.param.name = this.tradeNoVal;
            }
            arriveHistory(data).then((res) => {
                this.tableData = res.data.list;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.custRepeat {
    display: flex;
    flex-direction: column;
    .content {
        padding: 0 20px;
        .title {
            padding: 16px 0;
            font-size: 14px;
            color: #333333;
            font-weight: 600;
        }
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        text-align: center;
        bottom: 0;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
