<template>
    <div class="write">
        <div class="search-box">
            <div class="search-one">
                <div class="left">
                    <p>
                        <span>时间</span>
                        <el-date-picker
                            v-model="createDate"
                            type="month"
                            size="small"
                            style="width: 165px"
                            value-format="yyyy-MM"
                            placeholder="全部"
                            @change="
                                () => {
                                    currentPage = 1;
                                    pageSize = 20;
                                    getTableData();
                                    getStatistics();
                                }
                            "
                        >
                        </el-date-picker>
                    </p>
                    <p>
                        <span>入账公司</span>
                        <el-select
                            v-model="typeB"
                            size="small"
                            style="width: 190px"
                            placeholder="全部"
                            clearable
                            @change="
                                () => {
                                    currentPage = 1;
                                    pageSize = 20;
                                    getTableData();
                                    getStatistics();
                                }
                            "
                        >
                            <el-option
                                v-for="item in $tableDataHandle.selectTypeB()"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </p>
                    <p>
                        <span>类别</span>
                        <el-select
                            v-model="category"
                            size="small"
                            style="width: 165px"
                            placeholder="全部"
                            clearable
                            @change="
                                () => {
                                    currentPage = 1;
                                    pageSize = 20;
                                    getTableData();
                                    getStatistics();
                                }
                            "
                        >
                            <el-option label="直销" :value="1"></el-option>
                            <el-option label="渠道" :value="2"></el-option>
                            <el-option label="返点" :value="3"></el-option>
                            <el-option label="其他" :value="4"></el-option>
                        </el-select>
                    </p>
                    <p>
                        <span>回款状态</span>
                        <el-radio-group
                            size="small"
                            v-model="status"
                            @change="
                                () => {
                                    currentPage = 1;
                                    pageSize = 20;
                                    getTableData();
                                    getStatistics();
                                }
                            "
                        >
                            <el-radio-button label="">全部</el-radio-button>
                            <el-radio-button :label="1">待核销</el-radio-button>
                            <el-radio-button :label="2">已核销</el-radio-button>
                            <el-radio-button :label="4">支出</el-radio-button>
                        </el-radio-group>
                    </p>
                    <p>
                        <span>查询</span>
                        <el-input
                            size="small"
                            style="width: 200px"
                            placeholder="交易公司、交易金额"
                            v-model="fieldVal"
                            clearable
                        >
                        </el-input>
                        <el-button
                            @click="
                                () => {
                                    currentPage = 1;
                                    pageSize = 20;
                                    getTableData();
                                    getStatistics();
                                }
                            "
                            type="primary"
                            size="small"
                            icon="el-icon-search"
                        ></el-button>
                    </p>
                </div>
                <div class="right">
                    <p>
                        <span>
                            <img src="@/img/1.png" alt="" />
                            收入金额（{{ statistics.arriveCount }}笔）</span
                        >
                        <span>{{ statistics.totalArriveAmount }}</span>
                    </p>
                    <p>
                        <span>
                            <img src="@/img/2.png" alt="" />支出金额（{{
                                already.arriveCount
                            }}笔）</span
                        >
                        <span>{{ already.totalExpendAmount }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="table-title">
            <p>收入台账</p>
            <span>
                <button class="add" @click="onHistory">操作日志</button>
                
                <button class="add" @click="addWrite">
                    <i class="el-icon-plus"></i> 新建到款信息
                </button>

                <button @click="onImport()" class="cancel">
                    <i class="el-icon-plus" style="font-size: 12px"></i>
                    导入流水
                </button>
            </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="到账时间" prop="arriveTime" width="93">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.arriveTime
                                ? scope.row.arriveTime.slice(0, 10)
                                : '- -'
                        }}
                    </div>
                    <span v-if="scope.row.annotate != ''"><font color="#FF00000">有批注</font></span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="金额"
                prop="arriveAmount"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.status == 1"
                        type="text"
                        style="color: #2370eb"
                        @click="onEdit(scope.row)"
                        >
                        {{
                            returnAmount(scope.row)
                        }}
                        
                    </el-button>
                    <span v-else>
                        {{
                            returnAmount(scope.row)
                        }}
                    </span>
                    
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="付款方" width="193" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.status == 1"
                        type="text"
                        style="color: #2370eb"
                        @click="onEdit(scope.row)"
                        >
                        {{
                            returnContractA(scope.row)
                        }}
                        
                    </el-button>
                    <span v-else>
                        {{
                            returnContractA(scope.row)
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="收款方" width="193" show-overflow-tooltip>
                <template slot-scope="scope">
                    <!--<span class="text" v-if="scope.row.typeB != 9">
                        {{ $tableDataHandle.typeB(scope.row.typeB) }}
                    </span>
                    <span class="text" v-if="scope.row.typeB == 9">{{
                        scope.row.contractB
                    }}</span>
                    -->
                    <span>{{
                        returnContractB(scope.row)
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="付款方式" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span class="text">
                        {{
                            scope.row.entryMethod == 1
                                ? '对公'
                                : scope.row.entryMethod == 2
                                ? '微信'
                                : scope.row.entryMethod == 3
                                ? '支付宝'
                                : '- -'
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="类别"
                prop="staffAdminName"
                show-overflow-tooltip
                width="55"
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.category == 1
                                ? '直销'
                                : scope.row.category == 2
                                ? '渠道'
                                : scope.row.category == 3
                                ? '返点'
                                : scope.row.category == 4
                                ? '其他'
                                : '退款'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="可见范围"
                prop="staffAdminName"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    <el-popover
                        placement="right"
                        popper-class="abc"
                        width="200"
                        trigger="hover"
                    >
                        <div class="popover_box">
                            <h2>可见人数 {{ scope.row.returnPeopleLen }}</h2>
                            <div
                                v-for="(item, index) in scope.row
                                    .arriveAdminBOList"
                                :key="index"
                            >
                                <p
                                    class="title"
                                    @click="
                                        () => {
                                            item[0].basicFold =
                                                !item[0].basicFold;
                                            tableData = JSON.parse(
                                                JSON.stringify(tableData)
                                            );
                                        }
                                    "
                                >
                                    <span>
                                        {{
                                            item[0].departmentName + item.length
                                        }}
                                    </span>
                                    <el-button
                                        v-show="item[0].basicFold"
                                        type="text"
                                        style="
                                            margin-right: 12px;
                                            color: #2162cb;
                                            padding: 0;
                                        "
                                        icon="el-icon-arrow-up"
                                    >
                                    </el-button>
                                    <el-button
                                        v-show="!item[0].basicFold"
                                        type="text"
                                        style="
                                            margin-left: 0;
                                            margin-right: 12px;
                                            color: #2162cb;
                                            padding: 0;
                                        "
                                        icon="el-icon-arrow-down"
                                    >
                                    </el-button>
                                </p>
                                <el-collapse-transition>
                                    <div
                                        v-show="item[0].basicFold"
                                        class="collapse_transition_main"
                                    >
                                        <p v-for="itm in item" :key="itm.id">
                                            {{ itm.adminName }}
                                        </p>
                                    </div>
                                </el-collapse-transition>
                            </div>
                        </div>
                        <span slot="reference" style="cursor: pointer">{{
                            scope.row.returnPeopleLen || 0
                        }}</span>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="备注"
                width="173"
                prop="remark"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column
                label="操作"
                fixed="right"
                width="80"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.status == 1 && scope.row.bankType == 0"
                        type="text"
                        v-show="btnP.waitwriteVoid"
                        style="color: #2370eb"
                        @click="onVoid(scope.row)"
                        >作废</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新增 -->
        <el-dialog
            :visible.sync="drawerAddWrite"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新建到款信息
                </div>
            </template>
            <AddWrite @close="handleClose" />
        </el-dialog>
        <!-- 编辑 -->
        <el-dialog
            :visible.sync="drawerEditWrite"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    编辑到款信息
                </div>
            </template>
            <EditWrite @close="handleClose" ref="edit" />
        </el-dialog>
        <!-- 作废 -->
        <el-dialog
            :visible.sync="drawerVoid"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    作废到款
                </div>
            </template>
            <VoidWrite @close="handleClose" ref="void" />
        </el-dialog>
        <!-- 匹配回款（企微收款） -->
        <el-dialog
            :visible.sync="dialogMatching"
            width="760px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    匹配回款
                </div>
            </template>
            <Matching @close="handleClose" ref="matching" />
        </el-dialog>
        <!-- 导入 -->
        <el-dialog
            :visible.sync="dialogImport"
            width="560px"
            :before-close="handleImport"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    导入流水
                </div>
            </template>
            <Import ref="import" @close="handleImport" />
        </el-dialog>
    </div>
</template>
<script>
import { arriveList, statisticsList } from '@/api/user/finance/write';

import AddWrite from './addWrite.vue';
import EditWrite from './editWrite.vue';
import VoidWrite from './voidWrite.vue';
import Matching from './matching.vue';
import Import from '../import/import.vue';
export default {
    components: {
        AddWrite,
        EditWrite,
        VoidWrite,
        Matching,
        Import,
    },
    data() {
        return {
            statistics: {
                totalArriveAmount: 0,
                arriveCount: 0,
            },
            already: {
                totalArriveAmount: 0,
                arriveCount: 0,
            },
            field: '',
            fieldVal: '',
            createTime: '',
            createDate: '',
            category: '',
            typeB: '',
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            drawerAddWrite: false,
            drawerEditWrite: false,
            dialogMatching: false,
            drawerVoid: false,
            btnP: {},
            status: '',
            basicFold: false,
            dialogImport: false,
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            // console.log(btn);

            this.getStatistics();
            this.getTableData();
        },

        onHistory() {
            this.dialogMatching = true;
            setTimeout(() => {
                this.$refs.matching.getData();
                this.$refs.matching.$data.tradeNoVal = '';
            }, 0);
        },
        getStatistics() {
            let data = {
                param: { arriveStatusList: [1,2] },
            };
            if (this.fieldVal) {
                data.param.name = this.fieldVal;
            }
            if (this.createDate) {
                data.param.dateParam = this.createDate;
            }
            if (this.typeB) {
                data.param.typeB = this.typeB;
            }
            if (this.category) {
                data.param.category = this.category;
            }
            statisticsList(data).then((res) => {
                this.statistics = res.data;
            });
            let obj = {
                param: { arriveStatusList: [4] },
            };
            if (this.fieldVal) {
                obj.param.name = this.fieldVal;
            }
            if (this.createDate) {
                obj.param.dateParam = this.createDate;
            }
            if (this.typeB) {
                obj.param.typeB = this.typeB;
            }
            if (this.category) {
                obj.param.category = this.category;
            }

            statisticsList(obj).then((res) => {
                this.already = res.data;
            });
        },
        getTableData() {
            let data = {
                param: {
                    arriveStatusList: [1, 2],
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.fieldVal) {
                data.param.name = this.fieldVal;
            }
            if (this.createDate) {
                data.param.dateParam = this.createDate;
            }
            if (this.typeB) {
                data.param.typeB = this.typeB;
            }
            if (this.category) {
                data.param.category = this.category;
            }
            if (this.status) {
                data.param.arriveStatusList = [this.status];
            }
            arriveList(data).then((res) => {
                this.total = res.data.total;
                this.tableData = res.data.list;
                this.tableData.forEach((item) => {
                    item.returnPeopleLen = item.arriveAdminBOList.length;
                    item.arriveAdminBOList = this.sortClass(
                        item.arriveAdminBOList
                    );
                    item.arriveAdminBOList.forEach((itm) => {
                        itm[0].basicFold = true;
                    });
                });
            });
        },
        // 创建时间点击
        createitem(e) {
            e === this.createTime
                ? (this.createTime = '')
                : (this.createTime = e);

            this.getTableData();
        },
        returnAmount(row) {
            if(row.status == 4){
                return row.expendAmount;
            }
            return row.arriveAmount;
        },
        returnContractA(row) {
            if(row.status == 4){
                return row.contractB;
            }
            return row.contractA;
        },

        returnContractB(row) {
            if(row.status == 4){
                return row.contractA;
            }
            return row.contractB;
        },

        // 时间输入框变化
        expireTime() {
            if (this.createDate && this.createDate.length > 0) {
                this.createTime = '';
            }
            this.getTableData();
        },
        // 导入
        onImport() {
            this.dialogImport = true;
        },
        handleImport() {
            this.dialogImport = false;
            this.getTableData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        // 新建
        addWrite() {
            this.drawerAddWrite = true;
        },
        // 编辑
        onEdit(row) {
            if (this.btnP.waitwriteUpdate) {
                this.drawerEditWrite = true;
                let that = this;
                setTimeout(() => {
                    that.$refs.edit.getData(row);
                }, 50);
            }
        },
        sortClass(sortData) {
            const groupBy = (array, f) => {
                let groups = {};
                array.forEach((o) => {
                    if (o.departmentId && o.departmentName) {
                        let group = JSON.stringify(f(o));
                        groups[group] = groups[group] || [];
                        groups[group].push(o);
                    }
                });
                return Object.keys(groups).map((group) => {
                    return groups[group];
                });
            };
            const sorted = groupBy(sortData, (item) => {
                return {
                    value: item.departmentId,
                    label: item.departmentName,
                }; // 返回需要分组的对象
            });
            return sorted;
        },
        // 作废
        onVoid(row) {
            this.drawerVoid = true;
            let that = this;
            setTimeout(() => {
                that.$refs.void.getData(row);
            }, 50);
        },
        handleClose() {
            this.drawerAddWrite = false;
            this.drawerVoid = false;
            this.drawerEditWrite = false;
            this.dialogMatching = false;
            this.getTableData();
        },
        searchEmpty() {
            this.field = '';
            this.fieldVal = '';
            this.createTime = '';
            this.category = '';
            this.createDate = [];
            this.getTableData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.write {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            display: flex;
            align-items: center;
            height: 100%;
            .left {
                display: flex;
                background: #fff;
                flex-wrap: wrap;
                padding-top: 12px;
                p {
                    padding-bottom: 12px;
                }
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    margin-right: 16px;
                }
            }
            .right {
                width: 500px;
                margin-left: 10px;
                background: #fff;
                height: 100%;
                display: flex;
                p {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    span {
                        display: flex;
                        align-items: center;
                        img {
                            margin-right: 8px;
                        }
                    }
                    span:nth-child(2) {
                        font-size: 20px;
                        margin-top: 10px;
                        font-weight: 550;
                    }
                }
            }
        }
        .search-two {
            padding-bottom: 12px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.popover_box {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    h2 {
        padding: 11px 0;
        font-size: 14px;
        font-weight: normal;
        padding-left: 12px;
    }
    > div {
        width: 100%;
        .title {
            width: 100%;
            display: flex;
            cursor: pointer;
            padding-left: 12px;
            box-sizing: border-box;
            line-height: 2;
            background: #dfeafc;
            font-size: 13px;
            color: #2370eb;
            span {
                flex: 1;
            }
        }
    }
    .collapse_transition_main {
        padding: 5px 0 5px 12px;
        font-size: 13px;
        p {
            line-height: 1.8;
        }
    }
}
</style>
<style>
.abc {
    padding: 0 !important;
}
</style>
